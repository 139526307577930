









































































import {Component, Vue} from "vue-property-decorator";
import Company from "@/models/Company";
import CompanyService from "@/services/CompanyService";
import CompanyTab from "@/components/tabs/CompanyTab.vue";
import DistributorsTab from "@/components/tabs/DistributorsTab.vue";
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";

@Component({components:{CompanyTab, DistributorsTab}})
export default class CompanyView extends Vue {
	lang: any = getModule(LangModule).lang
	loading: boolean = false
	company: Company = new Company()
	tabs: string[] = ["/orders", "/distributors"]
	tab: number = 0

	created() {
		CompanyService.getMyCompany(this)
	}

}
